<template>
  <div
    v-if="type === 'label' || type === 'icon'"
    class="custom-label"
    :class="[
      { 'custom-label__disabled': disabled },
      { 'custom-label__full-width': isFullWidth },
      { 'custom-label__specific-width': specificWidth },
    ]"
    @click="handleClick"
  >
    <style v-if="specificWidth">
      :root {
        --specific-width: {{ `${specificWidth}` }}
      }
    </style>
    <SfLink
      v-if="link"
      :link="link"
      :class="`custom-label__label custom-label__label__${theme}`"
      :target="target"
      @click="
        (e) => {
          if (useLinkWithListener) {
            e.preventDefault();
            return false;
          }
        }
      "
    >
      <slot />
      <component
        v-if="hasIcon && type !== 'icon'"
        :is="theme === 'light' ? 'IconArrowBlack' : 'IconArrowWhite'"
        class="custom-label__icon"
      />
    </SfLink>
    <span v-else :class="`custom-label__label custom-label__label__${theme}`">
      <slot />
      <component
        v-if="hasIcon && type !== 'icon'"
        :is="theme === 'light' ? 'IconArrowBlack' : 'IconArrowWhite'"
        class="custom-label__icon"
      />
    </span>
  </div>
  <SfButton
    v-else
    :disabled="disabled"
    :type="type"
    :link="link"
    :aria-label="ariaLabel"
    :class="[
      `custom-button custom-button__${theme}`,
      { 'custom-button__transparent': isTransparent },
      { 'custom-button__transparent-on-hover': isTransparentOnHover },
      { 'custom-button__disabled': disabled },
      { 'custom-button__full-width': isFullWidth },
      { 'custom-button__specific-width': specificWidth },
    ]"
    @click="handleClick"
  >
    <style v-if="specificWidth">
      :root {
        --specific-width: {{ `${specificWidth}` }}
      }
    </style>
    <slot />
    <component
      v-if="hasIcon"
      :is="theme === 'light' ? 'IconArrowBlack' : 'IconArrowWhite'"
      class="custom-button__icon"
    />
  </SfButton>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { IconArrowBlack, IconArrowWhite } from '~/components/General/Icons';
import { SfButton, SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CustomCta',
  components: {
    SfLink,
    SfButton,
    IconArrowBlack,
    IconArrowWhite,
  },
  props: {
    theme: {
      type: String,
      default: 'light',
      validator(value) {
        return ['light', 'dark'].includes(value);
      },
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    isTransparentOnHover: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) =>
        ['button', 'submit', 'reset', 'label', 'icon'].includes(value),
    },
    link: {
      type: String,
      default: '',
    },
    useLinkWithListener: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: 'button',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    specificWidth: {
      type: String | undefined,
      default: undefined,
      validator: (value) =>
        value === undefined || value.includes('vw', 'px', '%', 'rem', 'em'),
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      if (
        (!props.disabled && props.link === '') ||
        (!props.disabled && props.link !== '' && props.useLinkWithListener)
      )
        emit('click');
    };
    return { handleClick };
  },
});
</script>

<style lang="scss" scoped>
.custom-label {
  width: fit-content;
  display: flex;
  align-items: center;
  &__label {
    display: contents;
    @include paragraph-m;
    cursor: pointer;
    &__light {
      color: var(--c-black);
    }
    &__dark {
      color: var(--c-white);
    }
  }
  &__disabled {
    cursor: auto;
    pointer-events: none;
    .custom-label__label {
      color: var(--c-dark-gray);
    }
    .custom-label__icon path {
      stroke: var(--c-dark-gray);
    }
  }
  &__full-width {
    width: 100%;
    min-width: 7.5rem;
  }
  &__specific-width {
    width: var(--specific-width);
    min-width: none;
    max-width: none;
  }
  &__icon {
    width: 1.375rem;
    height: 1.375rem;
  }
}
.custom-button {
  @include label-l;
  width: auto;
  min-width: var(--button-width);
  max-width: fit-content;
  border-radius: 100px;

  &__light {
    &:hover,
    &:active {
      @include from-desktop-min {
        color: var(--c-white);
        background: var(--c-black);
        svg path {
          stroke: var(--c-white);
        }
      }
      @include to-tablet-max {
        background: var(--c-white);
      }
    }
  }
  &__dark {
    color: var(--c-white);
    background: var(--c-black);
    border-color: var(--c-white);
    &:hover,
    &:active {
      @include from-desktop-min {
        color: var(--c-black);
        background: var(--c-white);
        border-color: var(--c-black);
        svg path {
          stroke: var(--c-black);
        }
      }
      @include to-tablet-max {
        background: var(--c-black);
      }
    }
  }
  &__transparent:not(:hover):not(:active) {
    background: transparent;
  }
  &__transparent-on-hover:hover,
  &__transparent-on-hover:active {
    background: transparent;
  }
  &__disabled {
    pointer-events: none;
    color: var(--c-dark-gray);
    background: var(--c-light-gray);
    border-color: var(--c-light-gray);
    svg path {
      stroke: var(--c-dark-gray);
    }
    @include from-desktop-min {
      &:hover {
        color: var(--c-black);
        background: var(--c-dark-gray);
        svg path {
          stroke: var(--c-black);
        }
      }
    }
  }
  &__full-width {
    width: 100%;
    max-width: 100%;
    min-width: 7.5rem;
  }
  &:before {
    content: none;
  }
  &:active {
    text-decoration: none;
  }
  &__icon {
    overflow: visible;
  }
  &__full-width {
    width: 100%;
    max-width: 100%;
  }
  &__specific-width {
    width: var(--specific-width);
    min-width: none;
    max-width: none;
  }
}
</style>
