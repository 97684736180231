<template>
  <div v-if="filteredQuestions.length > 0" class="faq-section">
    <div class="faq-section__title" v-if="title">{{ title }}</div>
    <div class="faq-section__questions">
      <CustomAccordion
        v-for="(question, index) in filteredQuestions"
        :key="index"
        class="faq-section__questions__accordion"
        layout="faq"
      >
        <template #title>
          <div class="faq-section__questions__accordion__title">
            <div class="faq-section__questions__accordion__title__index">
              {{ index + 1 }}
            </div>
            <div class="faq-section__questions__accordion__title__title">
              {{ question.title }}
            </div>
          </div>
        </template>
        <div class="faq-section__questions__accordion__content">
          {{ question.text }}
        </div>
      </CustomAccordion>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { CustomAccordion } from '~/components/General';
import { useMarkets } from '~/composables';

export default defineComponent({
  name: 'FaqSection',
  components: {
    CustomAccordion,
  },
  props: {
    title: {
      type: String,
      default: 'FAQ',
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { isUeMarket } = useMarkets();

    const filteredQuestions = computed(() => {
      if (props.questions.length > 0) {
        const marketQuestions = props.questions.map((q) =>
          !isUeMarket.value && q?.extraUe?.active ? q.extraUe : q
        );
        return marketQuestions.filter(
          (question) =>
            question && question.active && question.title && question.text
        );
      }
      return [];
    });

    return {
      filteredQuestions,
    };
  },
});
</script>

<style lang="scss" scoped>
.faq-section {
  margin-top: 5rem;
  width: 100%;
  &__title {
    @include mobile-h5;
    padding-bottom: 2.5rem;
  }
  &__questions {
    &__accordion {
      &__title {
        display: flex;
        align-items: center;
        &__index {
          @include paragraph-s;
          padding: 0 3.125rem 0 1.25rem;
        }
        &__title {
          @include paragraph-m;
        }
      }
      &__content {
        @include paragraph-m;
      }
    }
  }
  @include from-desktop-min {
    padding: 0 1.25rem;
    &__title {
      @include mobile-h4;
    }
    &__questions {
      &__accordion {
        &__title {
          &__index {
            @include label-s;
            padding-right: 0 5rem 0 1.25rem;
          }
          &__title {
            @include desktop-h6;
          }
        }
      }
    }
  }
}
</style>
