<template>
  <SfSidebar
    class="custom-sidebar"
    :visible="isSidebarOpen"
    :position="position"
    @close="handleClose"
    :title="name"
    :persistent="persistent"
  >
    <template #bar><span /></template>
    <template #title><span /></template>
    <template #circle-icon><span /></template>
    <template #content-top>
      <slot name="content-top">
        <div class="custom-sidebar__content-top">
          <IconCloseBlack
            v-if="hasCloseIcon && position === 'left'"
            class="custom-sidebar__content-top__close"
            @click="handleClose"
          />
          <slot name="content-top-title">
            <div class="custom-sidebar__content-top__title">
              {{ title }}
            </div>
          </slot>
          <IconCloseBlack
            v-if="hasCloseIcon && position === 'right'"
            class="custom-sidebar__content-top__close"
            @click="handleClose"
          />
        </div>
      </slot>
    </template>
    <slot />
    <template #content-bottom>
      <slot name="content-bottom" />
    </template>
  </SfSidebar>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfSidebar } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import { IconCloseBlack } from '~/components/General/Icons';

export default defineComponent({
  name: 'CustomSidebar',
  components: {
    SfSidebar,
    IconCloseBlack,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: 'right',
      validator(value) {
        return ['right', 'left'].includes(value);
      },
    },
    title: {
      type: String,
      default: '',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
    hasCloseIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { closeSidebar } = useUiState();
    const handleClose = () => {
      closeSidebar(props.name);
      emit('close');
    };

    return {
      handleClose,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-sidebar {
  &__content-top {
    display: flex;
    justify-content: space-between;
    &__title {
      @include paragraph-l;
    }
    &__close {
      @include pointer;
    }
    @include to-tablet-max {
      &__title {
        @include mobile-h5;
      }
    }
  }
}
</style>

<style lang="scss">
.custom-sidebar.sf-sidebar {
  .sf-sidebar {
    &__top {
      padding: 1.25rem;
    }
    &__content {
      padding: 1.25rem;
    }
    &__bottom {
      padding: 0;
    }
    @include from-desktop-min {
      &__aside {
        max-width: 28.4375rem;
        width: 100%;
      }
      &__top {
        padding: 1.875rem 1.25rem 0;
      }
    }
  }
}
</style>
