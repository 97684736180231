<template>
  <div class="custom-accordion" :class="`custom-accordion-${layout}`">
    <div class="custom-accordion__title" @click="showContent = !showContent">
      <slot name="title">
        <span class="custom-accordion__title__title">
          {{ title }}
        </span>
      </slot>
      <component :is="accordionIcon" class="custom-accordion__title__icon" />
    </div>
    <div v-if="showContent" class="custom-accordion__content">
      <slot />
    </div>
  </div>
</template>

<script>
import { ref, computed, defineComponent } from '@nuxtjs/composition-api';
import {
  IconPlusBlack,
  IconMinusBlack,
  SmallArrowBlackDown,
  SmallArrowBlackRight,
} from '~/components/General/Icons';

export default defineComponent({
  name: 'CustomAccordion',
  components: {
    IconPlusBlack,
    IconMinusBlack,
    SmallArrowBlackDown,
    SmallArrowBlackRight,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    layout: {
      type: String,
      default: 'standard',
      validator: (value) => ['standard', 'faq'].includes(value),
    },
  },
  setup(props) {
    const showContent = ref(false);

    const accordionIcon = computed(() => {
      if (props.layout === 'faq') {
        return showContent.value
          ? 'SmallArrowBlackDown'
          : 'SmallArrowBlackRight';
      } else {
        return showContent.value ? 'IconMinusBlack' : 'IconPlusBlack';
      }
    });

    return {
      showContent,
      accordionIcon,
    };
  },
});
</script>

<style lang="scss">
.custom-accordion {
  border-top: var(--general-border);
  &:last-child {
    border-bottom: var(--general-border);
  }
  &__title {
    @include paragraph-l;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3.75rem;
  }
  &-faq {
    border-color: var(--c-light-gray);
    &:last-child {
      border-color: var(--c-light-gray);
    }
    .custom-accordion {
      &__title {
        &__title {
          padding-left: 1.25rem;
        }
        &__icon {
          padding: 0 1.25rem;
          overflow: visible;
        }
      }
      &__content {
        padding: 0.625rem 1.25rem 1.875rem;
      }
    }
  }
  @include from-desktop-min {
    &-faq {
      .custom-accordion {
        &__content {
          padding: 0.625rem 5rem 1.875rem;
        }
      }
    }
  }
}
</style>
