<template>
  <SfModal
    class="custom-modal"
    :class="[{ 'has-image': image }, { 'full-page': fullPage }]"
    :title="title"
    :visible="isModalOpen"
    :persistent="persistent"
    :cross="false"
    @close="handleClose"
  >
    <template #modal-bar><span /></template>
    <div v-if="fullPage">
      <slot />
    </div>
    <div v-else class="custom-modal__inside">
      <div v-if="image" class="custom-modal__inside__image">
        <IconCloseBlack
          v-if="!isDesktop"
          class="custom-modal__inside__image__close"
          @click="handleClose"
        />
        <nuxt-img
          :src="isDesktop ? image.desktop : image.mobile"
          :alt="image.alt"
        />
      </div>
      <div class="custom-modal__inside__content">
        <div
          v-if="isDesktop || !image"
          class="custom-modal__inside__content__content-top"
        >
          <div class="custom-modal__inside__content__content-top__title">
            {{ title }}
          </div>
          <IconCloseBlack
            class="custom-modal__inside__content__content-top__close"
            @click="handleClose"
          />
        </div>
        <slot />
      </div>
    </div>
  </SfModal>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { IconCloseBlack } from '~/components/General/Icons';
import { SfModal } from '@storefront-ui/vue';
import { useUiState, useWindow } from '~/composables';

export default defineComponent({
  name: 'CustomModal',
  components: {
    IconCloseBlack,
    SfModal,
  },
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return value !== '';
      },
    },
    title: {
      type: String,
      default: '',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Object,
      default: null,
    },
    fullPage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { isDesktop } = useWindow();
    const { openedModal, closeModal } = useUiState();

    const isModalOpen = computed(
      () => props.name && openedModal.value === props.name
    );

    const handleClose = () => {
      closeModal(props.name);
      emit('close');
    };

    return {
      isDesktop,
      isModalOpen,
      handleClose,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-modal {
  &__inside {
    overflow-x: hidden;
    &__image {
      position: relative;
      display: flex;
      justify-content: center;
      &__close {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
      }
    }
    &__content {
      width: calc(100% - 2.5rem);
      padding: 1.875rem 1.25rem;
      &__content-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1.875rem;
        &__title {
          @include desktop-h6;
        }
        &__close {
          @include pointer;
        }
      }
    }
  }
  @include from-desktop-min {
    &__inside {
      display: flex;
      &__image {
        display: block;
        height: 100%;
      }
      &__content {
        width: calc(100% - 5rem);
        padding: 2.5rem;
        &__content-top {
          padding-bottom: 1.25rem;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.custom-modal.sf-modal {
  .sf-modal {
    &__container {
      border-radius: 0.625rem;
      width: auto;
    }
    &__content {
      border-radius: 0.625rem;
      padding: 0;
    }
    @include to-tablet-max {
      &__container {
        width: calc(100% - 2.5rem);
        margin: auto 1.25rem;
        justify-content: center;
      }
    }
  }
  &.has-image {
    @include to-tablet-max {
      .sf-modal {
        &__container {
          max-width: min-content;
          margin: auto;
        }
      }
    }
    @include from-desktop-min {
      .sf-modal {
        &__content {
          width: 50rem;
          aspect-ratio: 2/1;
          overflow: hidden;
        }
      }
    }
  }
  &.full-page {
    .sf-modal {
      &__container {
        margin: 0;
        width: 100vw;
        height: 100vh;
        max-height: 100vh;
        border-radius: unset;
      }
      &__content {
        border-radius: unset;
      }
    }
  }
}
</style>
