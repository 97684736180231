var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type === 'label' || _vm.type === 'icon')?_c('div',{staticClass:"custom-label",class:[
    { 'custom-label__disabled': _vm.disabled },
    { 'custom-label__full-width': _vm.isFullWidth },
    { 'custom-label__specific-width': _vm.specificWidth } ],on:{"click":_vm.handleClick}},[(_vm.specificWidth)?_c('style',[_vm._v("\n    :root {\n      --specific-width: "+_vm._s(("" + _vm.specificWidth))+"\n    }\n  ")]):_vm._e(),_vm._v(" "),(_vm.link)?_c('SfLink',{class:("custom-label__label custom-label__label__" + _vm.theme),attrs:{"link":_vm.link,"target":_vm.target},on:{"click":function (e) {
        if (_vm.useLinkWithListener) {
          e.preventDefault();
          return false;
        }
      }}},[_vm._t("default"),_vm._v(" "),(_vm.hasIcon && _vm.type !== 'icon')?_c(_vm.theme === 'light' ? 'IconArrowBlack' : 'IconArrowWhite',{tag:"component",staticClass:"custom-label__icon"}):_vm._e()],2):_c('span',{class:("custom-label__label custom-label__label__" + _vm.theme)},[_vm._t("default"),_vm._v(" "),(_vm.hasIcon && _vm.type !== 'icon')?_c(_vm.theme === 'light' ? 'IconArrowBlack' : 'IconArrowWhite',{tag:"component",staticClass:"custom-label__icon"}):_vm._e()],2)],1):_c('SfButton',{class:[
    ("custom-button custom-button__" + _vm.theme),
    { 'custom-button__transparent': _vm.isTransparent },
    { 'custom-button__transparent-on-hover': _vm.isTransparentOnHover },
    { 'custom-button__disabled': _vm.disabled },
    { 'custom-button__full-width': _vm.isFullWidth },
    { 'custom-button__specific-width': _vm.specificWidth } ],attrs:{"disabled":_vm.disabled,"type":_vm.type,"link":_vm.link,"aria-label":_vm.ariaLabel},on:{"click":_vm.handleClick}},[(_vm.specificWidth)?_c('style',[_vm._v("\n    :root {\n      --specific-width: "+_vm._s(("" + _vm.specificWidth))+"\n    }\n  ")]):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.hasIcon)?_c(_vm.theme === 'light' ? 'IconArrowBlack' : 'IconArrowWhite',{tag:"component",staticClass:"custom-button__icon"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }