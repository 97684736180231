import { render, staticRenderFns } from "./CustomSidebar.vue?vue&type=template&id=55b41346&scoped=true&"
import script from "./CustomSidebar.vue?vue&type=script&lang=js&"
export * from "./CustomSidebar.vue?vue&type=script&lang=js&"
import style0 from "./CustomSidebar.vue?vue&type=style&index=0&id=55b41346&prod&lang=scss&scoped=true&"
import style1 from "./CustomSidebar.vue?vue&type=style&index=1&id=55b41346&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b41346",
  null
  
)

export default component.exports